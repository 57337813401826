
import Vue from 'vue';
import { Component, Ref } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

import { CollectionType } from '@/enums/collection-type';
import { OnboardingStatus } from '@/enums/onboarding-status';
import type { Content } from '@/interfaces/components/content';
import type Provider from '@/interfaces/provider';

const Global = () => import(/* webpackChunkName: "home-global" */ '@/components/home/Global.vue');
const Onboarded = () => import(/* webpackChunkName: "home-onboarded" */ '@/components/home/Onboarded.vue');
const TasteSurvey = () =>
	import(/* webpackChunkName: "home-taste-onboarding" */ '@/components/home/taste-survey/TasteSurvey.vue');
const UnsupportedCountry = () =>
	import(/* webpackChunkName: "home-unsupported-country" */ '@/components/home/UnsupportedCountry.vue');

const Constant = namespace('constant');
const Language = namespace('language');
const User = namespace('user');

@Component({
	name: 'Home',
	components: {
		Global,
		Onboarded,
		TasteSurvey,
		UnsupportedCountry,
	},
})
export default class Home extends Vue {
	@Constant.Getter providersByShortName: Record<string, Provider>;

	@Language.Getter country: string;
	@Language.Getter language: string;

	@User.Getter isUserOnboarded: boolean;
	@User.State onboardingStatus: OnboardingStatus;

	@Ref('content') readonly content: Content;

	CollectionType = CollectionType;

	get showGlobal() {
		return this.onboardingStatus === OnboardingStatus.GLOBAL;
	}

	get showUnsupportedCountry() {
		return this.onboardingStatus === OnboardingStatus.UNSUPPORTED_COUNTRY;
	}

	get showTasteSurveyDesktop() {
		return this.onboardingStatus === OnboardingStatus.TASTE_ONBOARDING;
	}

	/**
	 *
	 * handle scrolling top of content element
	 */
	scrollToTop() {
		this.content.scrollToTop(250);
	}
}
